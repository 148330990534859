import momemt from 'moment';
import 'slick-carousel'

jQuery( document ).ready(function($) {

  // menu trigger
  $('.menu-trigger').click(function(){
    $('.menu-trigger, .nav').toggleClass('active')
  })

  $('.nav li a').click(function(){
    $('.menu-trigger, .nav').removeClass('active')
  })

  // Events
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Lola%20Brooke/events?app_id=45PRESS_LOLA_BROOKE',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' );
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let location = event.venue.city + ', ' + event.venue.region;
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '<div class="event-info">';
        html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMMM D Y' ) + '</div>';
        html += '<div class="event-artist">Lola Brooke</div>';
        html += '<div class="event-featuring">';
        if(event.lineup){
          for ( let lineup of event.lineup ) {
            html += '<span>' + lineup + '</span>';
          }
        }
        html += '</div>';
        html += '</div>';
        html += '<div class="event-pin">';
        html += '<div class="event-location">' + location + '</div>';
        html += '<div class="event-venue">' + event.venue.name + '</div>';
        html += '</div>';
        html += '<div class="event-links">';
        for ( let offer of event.offers ) {
          html += '<a href="' + offer.url + '" target="_blank" class="btn btn-green">' + offer.type + '</a>';
        }
        html += '</div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'No upcoming events.' );
      }
    }
  });

  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 165) {
        //clearHeader, not clearheader - caps H
      $("header").addClass("sticky");
    } else{
      $("header").removeClass("sticky");
    }
  }); //missing );

  // music slider
  $('.music-grid').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

});